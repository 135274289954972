import React from 'react';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Placeholder from '../assets/placeholder.jpg';

const IndexPage = args => {
  const course = args.data.course.edges[0].node;
  const { locale, slugs } = args.pathContext;
  const {
    title,
    chapters,
    introduction,
    coverImage,
  } = course;

  const lessonBlocks = chapters.filter(chapter => chapter.slug !== null).map((lesson, index) => {
    const backgroundImage = lesson.cover ? lesson.cover.fluid.src : Placeholder;
    const prefix = course.locale === 'en' ? '' : `/${ course.locale }`;

    return (
      <div className="space-bottom-2">
        <Link
          to={ `${ prefix }/chapter/${ lesson.slug }` }
          className="card-horizontal-lg card-aligned card-with-simple-overlay"
          key={ lesson.id }
        >
          <figure className="card-figure">
            <img className='card-figure-image' src={ backgroundImage } />
            <div className="card-figure-label-small">CHAPTER {index + 1}
            </div>
          </figure>
          <div className="card-main">
            <div className="card-body">
              <header className="card-header">
                <h3 className="card-title-light">{lesson.title}</h3>
              </header>
              <div className="card-text">
                {lesson.description}
              </div>
              <div className="card-footer">
                <div className="card-split">
                  <div className="card-split-item">
                    Learn it
                  </div>
                  <div className="card-split-item">
                    <i className='icon-arrow-long' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  });
  return (
    <Layout slugs={slugs} locale={locale}>
      <article className="module">
        <div className="module-container">
          <div className="module-content">
            <div className="module-header">
              <div className='module-header-image' style={ { backgroundImage: `url(${ coverImage.fluid.src })` } }>
                <div className="module-header-title">
                  <h1 className="module-title">{ title }</h1>
                </div>
              </div>
              <div className="module-header-content wrap--medium">
                <div className="module-introduction">
                  <p>{ introduction }</p>
                </div>
              </div>
            </div>
            <div className="module-body wrap">
              {
                chapters.length > 0 &&
                  <div>
                    <div className="section-divider">
                      <h3 className='section-divider-title' >
                        Chapters
                        <span className='section-divider-number'>({ chapters.length })</span>
                      </h3>
                    </div>
                    <section className='card-list'>
                      { lessonBlocks }
                    </section>
                  </div>
              }
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    slug: PropTypes.string,
    course: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
query CoursesQuery($locale: String!) {
  course: allDatoCmsCourse(filter: { locale: { eq: $locale } }) {
    edges {
      node {
        id
        slug
        title
        locale
        cardDescription
        introduction
        chapters {
          id
          slug
          title
          description
          cover {
            fluid(maxWidth: 3000, imgixParams: {w: "3000", fm: "png", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
            url
          }
        }
        coverImage {
          url
          fluid(maxWidth: 3000, imgixParams: {w: "3000", dpr: 2.0, fm: "png", auto: "compress"}) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
}
`;


export default IndexPage;
